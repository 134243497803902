@import "react-slideshow-image/dist/styles.css";
@media (min-aspect-ratio: 16/10) {
  body .productTable .indexCatImg {
    background-size: 65% !important;
    background-position: center !important;
  }
}
@media screen and (min-width: 800px) {
  body .intro {
    padding-top: 0;
    height: 423px;
  }
}
@media screen and (min-width: 2000px) {
  body .soldamaticImg {
    background-size: 80%;
    background-position: right top;
  }
}
@media screen and (max-width: 1000px) {
  body .referenceFoto {
    width: 100%;
    display: none;
  }
  body .referenceText {
    width: 100%;
  }
  body .each-slide {
    flex-direction: column-reverse;
  }
  body .contentSize {
    width: calc(100% - 40px);
    margin: auto;
  }
  body .parallax-logo-img-inv {
    height: 100px;
  }
  body .newsTextWrapper {
    width: 100%;
  }
  body .newsTextWrapper:nth-child(3n + 3) {
    margin: 0;
  }
  body .footerAddress {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  body .footerMap {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  body footer {
    padding-left: 15px;
    padding-right: 15px;
  }
  body .mapMobileBenesov {
    display: block;
  }
  body .mapDesktopBenesov {
    display: none;
  }
  body .introImg {
    background-position: center;
  }
  body .menuMobileButton {
    display: block;
  }
  body .navbarItems {
    display: none;
  }
  body .navbarItems {
    display: none;
    position: absolute;
    top: 65px;
    margin: 0;
    width: 100%;
    background-color: black;
    text-align: center;
    left: 0;
    right: 0;
    background-color: #1b1b1b;
  }
  body .navbarItems .menuItemContainer {
    display: block;
  }
  body .contactForm50 {
    width: 100%;
    float: left;
    padding: 4px;
    box-sizing: border-box;
  }
  body .contactForm50:nth-child(even) {
    margin-left: 0px;
  }
  body .contactForm50:nth-child(odd) {
    margin-right: 0px;
  }
  body .contactRow {
    margin-bottom: 0px;
  }
  body .productTable {
    flex-direction: column;
  }
  body .productTable .product {
    width: 100%;
    flex-direction: column;
  }
  body .content h2,
  body .contentHeading h2 {
    text-align: center;
  }
  body .ytV {
    width: 100%;
    height: 200px;
  }
  body .fiveProducts .content,
  body .threeProducts .content,
  body .fourProducts .content,
  body .twoProducts .content {
    width: 100%;
    text-align: center;
    padding: 40px 0px;
  }
  body .fiveProducts .content h2,
  body .threeProducts .content h2,
  body .fourProducts .content h2,
  body .twoProducts .content h2 {
    margin: 0 0 30px;
  }
  body .linkRect {
    margin-left: 0;
    margin-right: 0;
  }
  body .linkRect span {
    margin-left: 0;
    margin-right: 0;
  }
  body .footerAddress {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  body .logoEwmIndex {
    width: 80%;
  }
}
.textLeft {
  text-align: left;
}
.subcategoryList {
  text-align: left;
}
.subcategoryList li {
  margin-bottom: 20px;
}
.noBottomPadding {
  padding-bottom: 0 !important;
}
.noBottomMargin {
  margin-bottom: 0 !important;
}
.aZoom {
  transform: scale(1.05);
  box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.1);
}
.aTransition {
  transition: transform 0.2s, box-shadow 0.2s !important;
}
.aHoverZoom a {
  display: inline-block;
  text-decoration: none !important;
  transition: transform 0.2s, box-shadow 0.2s !important;
}
.aHoverZoom a:hover {
  transform: scale(1.05);
  box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.1);
}
.linkWithoutUnderline {
  text-decoration: none !important;
}
.linkWithoutUnderline img {
  transition: transform 0.3s;
}
.linkWithoutUnderline:hover {
  text-decoration: none !important;
}
.linkWithoutUnderline:hover img {
  transform: scale(1.1);
}
.pointer {
  cursor: pointer;
}
.horLine {
  border-bottom: 1px solid #000000;
}
html {
  height: 100%;
}
h2 {
  color: white;
  text-align: center;
}
.inputStyle {
  border: 1px solid #cccccc;
  border-radius: 3px;
  color: #999999;
  font-size: 15px;
}
button {
  height: 50px;
  padding: 10px 20px 10px 20px;
  text-transform: uppercase;
  font-size: 12px;
  color: #797979;
  font-weight: bold;
  background-color: #eeeeee;
  border: 1px solid #cccccc;
  border-radius: 3px;
  color: #999999;
  font-size: 15px;
}
input {
  border: 1px solid #cccccc;
  border-radius: 3px;
  color: #999999;
  font-size: 15px;
  padding-left: 40px;
  padding-right: 15px;
  box-sizing: border-box;
}
textarea {
  resize: none;
  border: 1px solid #cccccc;
  border-radius: 3px;
  color: #999999;
  font-size: 15px;
  padding: 15px;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  color: white;
  background-color: black;
  min-height: 100%;
  position: relative;
}
a {
  color: #e1e1e1;
  text-decoration: none;
}
.section a {
  text-decoration: underline;
}
a:hover {
  text-decoration: underline;
  -webkit-transition: color 0.3s;
  /* Safari */
  transition: color 0.3s;
}
.noUnderline {
  text-decoration: none;
}
.noUnderline:hover {
  text-decoration: none;
}
li {
  margin-bottom: 0px;
}
label {
  display: block;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
h2 {
  font-weight: 800;
  letter-spacing: 0.14em;
  font-size: 30px;
  margin: 0 0 30px;
  text-transform: uppercase;
}
.blackOver {
  width: 100%;
  height: calc(100vh -  65px );
  background-color: black;
  position: absolute;
  z-index: 2000;
  transition: opacity 1s ease-in-out;
}
.blur {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 2000;
  background-color: black;
  opacity: 0.5;
  filter: blur(50px);
}
.parallaxContainer {
  width: 100%;
  height: calc(100vh -  65px );
  position: relative;
  overflow: hidden;
  background-color: black;
  transform-style: preserve-3d;
}
.menuW {
  position: absolute;
  height: 65px;
  background-color: #1b1b1b;
  line-height: 60px;
  padding-left: 0;
  width: 100%;
  font-weight: 500;
  z-index: 500000;
  border-bottom: 1px solid #000000;
  text-align: right;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}
.menuW a,
.menuW .technologyMenuButton {
  margin: 13px;
  vertical-align: middle;
  color: white;
  cursor: pointer;
}
.menuW .technologyMenuButton:hover span {
  text-decoration: underline;
}
.menuW .technologyMenuButton {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}
.menuTechnologyContainer {
  display: none;
  position: absolute;
  background-color: #2c2c2c;
  left: 0;
  right: 0;
  margin: auto;
  width: 115px;
  left: -9px;
}
.menuF {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0px;
  height: 65px;
  background-color: black;
  line-height: 60px;
  padding-left: 40px;
  width: calc(100% -  40px );
  font-weight: 500;
  z-index: 5000;
  border-bottom: 1px solid #000000;
  text-align: right;
  user-select: none;
}
.menuF a {
  margin: 17px;
  vertical-align: middle;
}
.parallax-logo {
  position: absolute;
  top: calc(50vh -  75px );
  width: 300px;
  height: 150px;
  z-index: 3000;
}
.menuMobileButton {
  font-size: 30px;
  display: none;
  cursor: pointer;
  width: 40px;
  position: absolute;
  right: 30px;
  top: 3px;
}
.parallax-logo-img {
  background-size: auto 100%;
  width: 100px;
  height: 50px;
  left: -150px;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url("../img/logo_inv.png");
  z-index: 3000;
  position: absolute;
  top: 8px;
  transition: left 1s;
}
.parallax-logo-img-inv {
  position: absolute;
  top: calc(50vh -  75px );
  width: 300px;
  height: 150px;
  margin-left: 100px;
  background-size: auto 100%;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url("../img/logo_inv.png");
  z-index: 3000;
  transition: top 2s;
}
.contentSize {
  width: 1000px;
  margin: auto;
  z-index: 20;
  position: relative;
}
.contentSize:after {
  content: "";
  display: block;
  clear: both;
}
.navbarItems {
  width: 1000px;
  margin: auto;
  z-index: 20;
  position: relative;
  text-align: center;
}
@keyframes heey {
  0% {
    transform: scale(1, 1);
  }
  2% {
    transform: scale(1.15, 1.15);
  }
  4% {
    transform: scale(1, 1);
  }
  6% {
    transform: scale(1.15, 1.15);
  }
  8% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.menuItemContainer:last-child a {
  color: red !important;
  animation: heey 6s forwards;
  animation-iteration-count: infinite;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 19px;
}
.menuItemContainer {
  display: inline;
}
.bgWhite {
  background-color: white !important;
  color: black !important;
}
.bgWhite a {
  color: black !important;
}
.bgWhite h2 {
  color: black !important;
}
.contactForm {
  padding-bottom: 50px;
}
.contactForm input {
  width: 100%;
  height: 40px;
}
.contactForm .privacyCheckbox {
  width: auto;
  height: auto;
  margin-right: 10px;
  float: left;
}
.contactForm .newsletterCheckbox {
  margin-top: 10px;
}
.contactForm button {
  margin-top: 30px;
  float: right;
}
.contactAddress {
  padding: 40px;
  background-color: #666666;
}
.contactForm50 {
  width: calc(50% - 20px);
  float: left;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
}
.contactRow {
  margin-bottom: 30px;
  overflow: auto;
}
.inputIcon {
  position: absolute;
  margin-left: 15px;
  margin-top: 11px;
}
.contactForm50:nth-child(even) {
  margin-left: 20px;
}
.contactForm50:nth-child(odd) {
  margin-right: 20px;
}
.footerContactIcon {
  color: grey;
  left: 5px;
}
.contactForm100 {
  margin-top: 50px;
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
}
.contactForm100 textarea {
  width: 100%;
  height: 200px;
}
.ddd {
  position: absolute;
  top: 0;
}
.news {
  overflow: auto;
}
.newsBig {
  overflow: auto;
  margin-bottom: 50px;
}
.newsBigH3 {
  font-size: 1.9em;
}
.newsTextWrapper {
  width: calc(33% - 18px);
  overflow: hidden;
  float: left;
  color: #797979;
  text-decoration: none !important;
}
.newsTextWrapper h3 {
  display: block;
  font-size: 14px;
  text-align: center;
  height: 60px;
}
.newsTextWrapper p {
  text-align: left;
}
.newsDate {
  color: grey;
  margin: 10px 0;
  font-size: 13px;
}
.newsText {
  overflow: hidden;
}
.newsTextWrapper:nth-child(3n + 1) {
  margin: 0px 30px 0 0;
}
.newsTextWrapper:nth-child(3n + 2) {
  margin: 0px 0 0 0;
}
.newsTextWrapper:nth-child(3n + 3) {
  margin: 0px 0 0 30px;
}
.newsImage {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  filter: saturate(0);
  transition: filter 1s;
}
.introImg {
  width: 100%;
  height: 70vh;
  background-image: url("../img/bg/1black.jpg");
  position: relative;
  background-color: black;
  background-size: 100%;
  background-position: -100px -260px;
  background-repeat: no-repeat;
}
.soldamaticImg {
  width: 100%;
  height: 70vh;
  background-image: url("../img/Soldamatic.jpg");
  background-color: white;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.center {
  text-align: center !important;
}
.newsImage:hover {
  filter: saturate(1);
}
.newsImageColor {
  filter: saturate(1) !important;
}
.newsImageBig {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
}
.newsLink {
  color: grey;
  margin: 10px 0;
  font-size: 13px;
  display: block;
  cursor: pointer;
  text-align: right;
  margin: 0;
}
.articleImg {
  width: 100%;
}
.clear {
  clear: both;
}
.intro {
  padding-top: 53%;
  overflow: hidden;
}
.intro .content {
  padding: 130px 10px 100px 10px;
}
.introVideo {
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.soldamatic.section {
  background-color: white;
  color: black;
}
.soldamatic.section h2 {
  color: black;
}
.soldamatic.section .content {
  padding: 130px 10px 30px 10px;
}
.content {
  padding: 80px 10px 80px 10px;
  font-size: 1rem;
  line-height: 1.5;
  z-index: 100;
  text-align: center;
}
.contentSmallerPadding {
  padding: 0px 10px 0px 10px;
  font-size: 1rem;
  line-height: 1.5;
  z-index: 100;
  text-align: center;
}
.contentHeading {
  padding: 10px 10px 0 10px;
  font-size: 1rem;
  line-height: 1.5;
  z-index: 100;
}
.im {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: relative;
  width: 100%;
  height: 100%;
}
.Line {
  width: 100%;
  height: 50vh;
  background-color: #000000;
  overflow: hidden;
  position: relative;
  z-index: 3;
  z-index: 1;
}
.LineSmall {
  width: 100%;
  height: 20vh;
  background-color: #000000;
  overflow: hidden;
  position: relative;
  z-index: 3;
  z-index: 1;
}
.LineBg {
  width: 100%;
  height: 100vh;
  top: 0;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
}
.LineBg1 {
  width: 100%;
  height: 100vh;
  top: 0;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  background-image: url("../img/bg/1black.jpg");
  z-index: 2;
}
.LineBg2 {
  width: 100%;
  height: 100vh;
  top: 0;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  background-image: url("../img/bg/b2.jpg");
  z-index: 2;
}
.LineBgDnyEwm {
  width: 100%;
  height: 100vh;
  top: 0;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  background-image: url("../img/ewm_dny.jpg");
  z-index: 2;
}
.LineBg3 {
  width: 100%;
  height: 100vh;
  top: 0;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  background-image: url("../img/bg/b3.jpg");
  z-index: 2;
}
.LineBgPico {
  width: 100%;
  height: 100vh;
  top: 0;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  background-image: url("../img/photos/pico2.jpg");
  z-index: 2;
}
.LineBgOrbit {
  width: 100%;
  height: 100vh;
  top: 0;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  background-image: url("../img/photos/orbital3.jpg");
  z-index: 2;
  background-position: center bottom;
}
.BgOrbit {
  background-image: url("../img/photos/orbit2.jpg");
  z-index: 2;
  background-position: center top;
  height: 300px;
}
.bgg {
  background-image: url("../img/bg/b1.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
header {
  height: 100vh;
  width: 100%;
  position: relative;
  perspective: 300px;
  user-select: none;
}
.ytV {
  width: 730px;
  height: 410px;
  margin: auto;
}
.videoSlider {
  height: 410px;
}
.section {
  z-index: 100000;
  position: relative;
  background-color: black;
  overflow: hidden;
}
.staticBg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg1,
.bg2,
.bg3 {
  color: black !important;
  background-color: #dfdfdf;
}
.bg1 .content,
.bg2 .content,
.bg3 .content {
  text-align: left;
}
.bg1 h2,
.bg2 h2,
.bg3 h2,
.bg1 a,
.bg2 a,
.bg3 a {
  color: black !important;
}
.bg1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/bg/1.jpg");
}
.bg2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/bg/2.jpg");
}
.bg3 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/bg/3.jpg");
}
footer {
  background-color: #363636;
  font-size: 15px;
  color: #ffffff;
  overflow: auto;
  transform: translateZ(0px) scale(1);
}
footer h2,
footer h3 {
  color: #ccc;
}
.videoList {
  margin-bottom: 100px;
}
.footerAddress {
  width: calc(50% - 20px);
  float: left;
  margin: 10px;
}
.footerAddress strong {
  display: block;
  text-transform: uppercase;
}
.footerMap {
  width: calc(50% - 20px);
  float: left;
  margin: 10px;
  height: 200px;
}
.footerMap strong {
  display: block;
  text-transform: uppercase;
}
.mapMobileBenesov {
  display: none;
}
.mapDesktopBenesov {
  display: block;
}
.bg {
  background-image: url("../img/bg.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 3s;
  transition-delay: 1s;
}
.fg {
  background-image: url("../img/small/fg.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 1s;
}
.m {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 1s;
}
.mlowres {
  background-image: url("../img/small/m.png");
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 3s;
}
.mhires {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 1s;
}
.p {
  background-image: url("../img/small/p.png");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax {
  height: 100%;
  /* fallback for older browsers */
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-perspective: 300px;
  perspective: 300px;
}
.parallaxOver {
  height: 100vh;
  /* fallback for older browsers */
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 10000;
}
.parallaxWrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
}
.contentWrapper {
  z-index: 10;
  position: relative;
  margin-top: calc(100vh -  65px );
  background: black;
}
.parallax__group {
  position: relative;
  height: 100%;
  /* fallback for older browsers */
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.parallax__layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.parallax__layer--base {
  transform: translateZ(-100px) scale(1.6);
  z-index: 4;
  height: 100vh;
}
.parallax__layer--back {
  transform: translateZ(-300px) scale(2.1);
  z-index: 3;
}
.parallax__layer--deep {
  transform: translateZ(-600px) scale(3.2);
  z-index: 2;
}
.parallax__layer--logo {
  transform: translateZ(100px) scale(1);
  z-index: 5;
}
.contentMargin {
  padding-top: 66px;
}
.adjust-hue {
  -webkit-animation: fa-adjust-hue 2s infinite linear;
  animation: fa-adjust-hue 2s infinite linear;
}
.submitOk {
  background-color: #c8ffcb;
  border-color: green;
  color: green;
}
.submitError {
  background-color: #ffe5e3;
  border-color: red;
  color: red;
}
.videoTopMargin {
  margin-top: 80px;
}
.linkRect {
  margin: 20px;
  font-weight: bold;
  text-align: center;
  display: block;
  text-decoration: none !important;
  color: #b30019;
}
.linkRect span {
  margin: 50px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
}
.linkRect span:hover {
  color: #b30019;
}
.productImage {
  height: 200px;
  vertical-align: middle;
  position: relative;
  display: block;
  margin: 0 auto 50px auto;
}
.productImage img {
  max-height: 200px;
  max-width: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.productsMain .content {
  float: left;
}
.productsMain .content p {
  font-size: 12px;
}
.productsMain .content:after {
  display: block;
  content: "";
  clear: both;
}
.twoProducts .content {
  float: left;
}
.twoProducts .content p {
  font-size: 12px;
}
.twoProducts .content:after {
  display: block;
  content: "";
  clear: both;
}
.twoProducts .content {
  width: calc(50% - 20px);
}
.threeProducts {
  padding: 80px 0;
  box-sizing: border-box;
}
.threeProducts .content {
  float: left;
}
.threeProducts .content p {
  font-size: 12px;
}
.threeProducts .content:after {
  display: block;
  content: "";
  clear: both;
}
.threeProducts .content {
  width: calc(33.3%);
  padding: 20px;
  box-sizing: border-box;
}
.fourProducts {
  padding-top: 130px;
}
.fourProducts .content {
  float: left;
}
.fourProducts .content p {
  font-size: 12px;
}
.fourProducts .content:after {
  display: block;
  content: "";
  clear: both;
}
.fourProducts .content {
  width: calc(25% - 20px);
}
.fiveProducts {
  padding: 67px 0;
  box-sizing: border-box;
}
.fiveProducts h2 {
  font-size: 20px;
}
.fiveProducts .titanColumn h2 {
  font-size: 27px;
}
.fiveProducts .titanColumn p {
  font-size: 15px;
  font-weight: bold;
}
.fiveProducts .titanColumn img {
  max-height: 232px;
  bottom: auto;
}
.fiveProducts .taurusColumn img {
  bottom: auto;
  max-height: 165px;
}
.fiveProducts .wegaColumn img {
  max-height: 160px;
}
.fiveProducts p {
  height: 60px;
}
.fiveProducts .content {
  float: left;
}
.fiveProducts .content p {
  font-size: 12px;
}
.fiveProducts .content:after {
  display: block;
  content: "";
  clear: both;
}
.fiveProducts .content {
  width: calc(20%);
  padding: 20px;
  box-sizing: border-box;
}
.inNumbers {
  text-align: center;
}
.inNumbers h2 {
  margin-bottom: 50px;
}
.inNumbers .productImage {
  height: 70px;
}
.technology h3 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.technology ul {
  margin-bottom: 50px;
}
.technology p,
.technology li {
  text-align: left;
}
.usedTechnologyWrapper {
  text-align: center;
}
.usedTechnology {
  color: white;
  border: 1px solid white;
  width: 30%;
  padding: 30px;
  text-align: center;
  margin: 0px 30px 30px 30px;
  display: inline-block;
  text-decoration: none !important;
}
.usedTechnologyPage {
  color: white;
  border: 1px solid white;
  width: 150px;
  padding: 16px;
  text-align: center;
  margin: 30px 30px 0px 30px;
  display: inline-block;
  text-decoration: none !important;
}
.noBottomPadding {
  padding-bottom: 0 !important;

}
.noTopPadding {
  padding-top: 0 !important;
}
.productTable {
  width: 100%;
  position: relative;
  background-color: black;
  padding-bottom: 40px;
  padding-top: 50px;
  display: flex;
  align-items: center;
}
.productTable a {
  text-decoration: none;
}
.productTable h1 {
  margin-bottom: 10px;
}
.productTable h2 {
  font-size: 15px;
}
.productTable .product {
  vertical-align: top;
  display: inline-block;
  width: 50%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 5px;
  margin-bottom: 30px;
}
.productTable h3 {
  font-size: 30px;
  margin-bottom: 0px;
}
.productTable p {
  font-size: 15px;
}
.productTable .indexCatImg {
  height: 400px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
.productTable .indexCatImgSmall {
  height: 200px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
.productTable .indexCatImgContain {
  height: 400px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.productTable .bgContain {
  background-size: contain !important;
}
.productTable .indexCatImgBig {
  height: 600px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}
.productTable .indexCatImg2 {
  height: 450px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}
.productTable .indexCatImg2 {
  height: 250px;
  width: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
}
.productTable .cover {
  background-size: cover !important;
}
.productTable .migmag {
  background-image: url("../img/index/MigMag.jpg");
  background-position: center;
}
.productTable .plasma {
  background-image: url("../img/index/Plasma.jpg");
}
.productTable .mma {
  background-image: url("../img/index/Mma.jpg");
  background-position: center;
}
.productTable .pico3 {
  background-image: url("../img/photos/pico3.jpg");
  background-position: center;
}
.productTable .pico4 {
  background-image: url("../img/photos/pico4.jpg");
  background-position: center;
}
.productTable .pico5 {
  background-image: url("../img/photos/pico5.jpg");
  background-position: center;
}
.productTable .robot {
  background-image: url("../img/photos/robot.jpg");
  background-position: bottom;
}
.productTable .orbit {
  background-image: url("../img/photos/orbit.jpg");
  background-position: center;
}
.productTable .zaruka {
  background-image: url("../img/zaruka.png");
  background-position: center;
  background-size: contain;
}
.productTable .doosan {
  background-image: url("../img/doosan/doosan.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .doosanB {
  background-image: url("../img/doosan/doosan_black.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .doosanM {
  background-image: url("../img/doosan/m0609_2.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .doosanA {
  background-image: url("../img/doosan/a0509.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .doosanH {
  background-image: url("../img/doosan/h_2017_3.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .doosan3 {
  background-image: url("../img/doosan/h_2017_3.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .catering {
  background-image: url("../img/catering.jpg");
  background-position: center;
  background-size: cover;
}
.productTable .harmony {
  background-image: url("../img/harmony.jpg");
  background-position: center;
  background-size: cover;
}
.productTable .tigspeed {
  background-image: url("../img/photos/tig/tigspeed.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .tetrix {
  background-image: url("../img/photos/tetrix_351.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .tetrixP {
  background-image: url("../img/photos/tetrix_photo.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .tetrixAcdc {
  background-image: url("../img/photos/tetrix_acdc.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .picotigB {
  background-image: url("../img/photos/picotig_black.jpg");
  background-position: center;
  background-size: contain;
}
.productTable .tig {
  background-image: url("../img/index/Tig.jpg");
}
.productTableNoTop {
  padding-top: 0;
}
.fourProducts h2 {
  margin-bottom: 30px;
}
.soldamatic .threeProducts h2 {
  margin: 0;
}
.soldamatic h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}
.soldamatic p {
  text-align: center;
}
.privacy h1 {
  text-align: center;
}
.privacy h2 {
  margin: 50px 0 10px 0;
  font-size: 22px;
}
.privacy p,
.privacy li {
  text-align: left;
}
.newsIndex {
  background-color: white;
  color: black !important;
  padding-bottom: 50px;
}
.newsIndex h2 {
  color: black;
}
.orderShowButton {
  color: white;
  background-color: red;
  border: 1px solid red;
  float: none !important;
  cursor: pointer;
  transition: transform 0.2s;
}
@keyframes poping {
  0% {
    transform: scale(1.1, 1.1);
  }
  50% {
    transform: scale(1.15, 1.15);
  }
  100% {
    transform: scale(1.1, 1.1);
  }
}
.orderShowButton:hover {
  animation: poping 0.5s forwards;
  animation-iteration-count: infinite;
}
.showFreeP {
  margin-bottom: 40px;
}
.showFreeH {
  color: red;
}
.logoEwmIndex {
  width: 300px;
  margin: 30px;
}
.logoLink {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
}
.react-slideshow-container {
  position: relative;
}
.each-slide {
  display: flex;
}
.sliderArrow {
  padding: 10px;
  color: grey;
  font-size: 25px;
}
.sliderArrowLeft {
  padding: 10px;
  color: grey;
  font-size: 25px;
  left: 0;
  position: absolute;
}
.sliderArrowRight {
  padding: 10px;
  color: grey;
  font-size: 25px;
  right: 0;
  position: absolute;
}
.referenceFoto {
  width: 50%;
  box-sizing: border-box;
  background-size: cover;
  background-color: white;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.referenceLogo {
  width: 100%;
  height: 100px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}
.referenceText {
  width: 50%;
  box-sizing: border-box;
  background-color: white;
  color: black;
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 350px;
}
.referenceText h2 {
  color: black;
  text-align: left;
  font-size: 18px;
  margin: 0;
  text-align: center;
}
.referenceText p {
  font-size: 12px;
  margin-top: 0;
  text-align: center;
}
.paddingTopContent {
  padding-top: 40px;
}
.brozura {
  background-color: grey;
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  margin-top: 25px;
  cursor: pointer;
  color: white !important;
}
.bgWhite .brozura {
  color: white !important;
}


.mapEwmDay {
  width: 70%;
  margin: auto;
}